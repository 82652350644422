import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    htmlFontSize: 17,
    fontFamily: [
      'Oswald',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#ff0009'
    },
    secondary: {
      main: '#000000'
    },
    background: {
      default: '#666666',
      secondary: '#222'
    },
    text: {
      primary: '#dddddd',
      secondary: 'rgb(157,157,157)'
    }
  }
});

export default theme;
